<template>
    <modal ref="AgregarDesconocido" titulo="" icon="grocery" noAceptar adicional="Agregar" @adicional="agregar">
        <ValidationObserver ref="form2">
            <div class="row mx-0 justify-center">
                <div class="col-10 text-center">
                    Haz una breve descripción del producto y nosotros lo encontraremos por tí.
                </div>
            </div>
            <div class="row mx-0 my-3 justify-center">
                <div class="col-10">
                    <label>Descripción del producto</label>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="descripción del producto">
                        <el-input v-model="model.desconocido" type="textarea" show-word-limit maxlength="250" :rows="3" />
                        <span class="text-danger f-11">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Almacen from "~/services/almacen/almacen"
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            model:{
                desconocido:'',
            },
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_historial_tendero/id_pedido',
        }),
    },
    methods:{
        toggle(){
            this.model = {
                desconocido:''
            }
            this.$refs.AgregarDesconocido.toggle()
        },
        async agregar(){
            try {
                const valid = await this.$refs.form2.validate()
                if(valid){
                    const {data} = await Almacen.agregar_desconocido(this.model)
                    this.$store.commit('pedidos/pedidos_tendero/agregar_desconocido',data.producto) // altera el carrito
                    this.$refs.AgregarDesconocido.toggle()
                }
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
